import { memo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Title } from '../../Title'
import FAQOption from './FAQOption/FAQOption'

import './OpenFinanceFAQ.css'

const OpenFinanceFAQ = () => {
  const { t } = useTranslation()

  return (
    <div className={'OpenFinanceFAQ'}>
      <Title className={'title'}>{t('open-finance-faq.title')}</Title>

      <FAQOption
        question={t('open-finance-faq.questions.sharing.title')}
        answer={
          <Trans
            i18nKey="open-finance-faq.questions.sharing.description"
            components={{
              ol: <ol />,
              li: <li />,
              strong: <strong />,
              br: <br />,
            }}
          />
        }
      />
      <FAQOption
        question={t('open-finance-faq.questions.openFinance.title')}
        answer={t('open-finance-faq.questions.openFinance.description')}
      />
      <FAQOption
        question={t('open-finance-faq.questions.initiator.title')}
        answer={
          <Trans
            i18nKey="open-finance-faq.questions.initiator.description"
            components={{
              br: <br />,
              strong: <strong />,
              div: <div />,
            }}
          />
        }
      />
      <FAQOption
        question={t('open-finance-faq.questions.manageData.title')}
        answer={
          <Trans
            i18nKey="open-finance-faq.questions.manageData.description"
            components={{
              a: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  target="_blank"
                  href="https://portal.iniciador.com.br/"
                  rel="noreferrer"
                />
              ),
              div: <div />,
            }}
          />
        }
      />
      <FAQOption
        question={t('open-finance-faq.questions.connectMultiple.title')}
        answer={t('open-finance-faq.questions.connectMultiple.description')}
      />
      <FAQOption
        question={t('open-finance-faq.questions.noAccount.title')}
        answer={t('open-finance-faq.questions.noAccount.description')}
      />
    </div>
  )
}

export default memo(OpenFinanceFAQ)
