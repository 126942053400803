const CALLBACK_PARAM_KEYS = [
  'error',
  'item_id',
  'execution_status',
  'item_status',
  'connector_id',
  'connector_name',
  'events',
  'timestamp',
] as const
/**
 * Valid callback param keys
 */
type CallbackParamKey = (typeof CALLBACK_PARAM_KEYS)[number]

/**
 * Set (or override) a param on the current URL, based on a given key.
 *
 * @param key
 * @param value
 */
export function setUrlParam(key: CallbackParamKey, value: string): void {
  const {
    location: { search: currentSearchParams, origin: currentOrigin },
  } = window
  const urlParams = new URLSearchParams(currentSearchParams)
  urlParams.set(key, value)
  const newUrl = `${currentOrigin}/?${urlParams.toString()}`
  window.history.replaceState({}, document.title, newUrl)
}

/**
 * Retrieve the value of an URL param by key, or null if not defined
 * @param key
 */
export function getUrlParam(key: CallbackParamKey): string | null {
  return new URLSearchParams(window.location.search).get(key)
}

/**
 * Delete a query param from the current URL.
 * @param key
 */
export function clearUrlParam(key: CallbackParamKey): void {
  const {
    location: { search: currentSearchParams, origin: currentOrigin },
  } = window
  const urlParams = new URLSearchParams(currentSearchParams)
  urlParams.delete(key)
  const newUrl = `${currentOrigin}/?${urlParams.toString()}`
  window.history.replaceState({}, document.title, newUrl)
}

/**
 * Delete all callback query params from the current URL.
 */
export function clearAllUrlParams(): void {
  for (const key of CALLBACK_PARAM_KEYS) {
    clearUrlParam(key)
  }
}
